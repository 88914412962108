import { useMemo, useRef, Fragment } from "react";
import { useRect } from "@reach/rect";
import { usePlainCss } from "../hooks";
import { Portal, Tooltip } from ".";
let counter = 0;

/**
 * A portaled close icon creates a close icon rendered to a given container. This allows
 * you to put the close icon on the top right corner of an `overflow:hidden` container and
 * the tooltip gets rendered correctly.
 */
const CloseIcon = _ref => {
  let {
    width,
    color,
    tooltipText,
    framed,
    renderInContainer,
    tooltipAlways,
    onClick,
    thickness = 1
  } = _ref;
  const idCloseIconNotPortaled = useMemo(() => `react-close-icon-${(counter++).toString(36)}`, []);
  const idCloseIconPortaled = useMemo(() => `react-close-icon-${(counter++).toString(36)}`, []);

  /**
   * Why two containers for a simple close icon? I need to explain something technical here.
   * Our complete banner/dialog is tainted with `overflow:hidden` due to flickering effects
   * with animate.css etc. We will now create an invisible element with the placeholder,
   * we will then read its positioning (rect) and generate the visible close icon based on this position.
   */
  const idCloseIconPlaceholder = useMemo(() => `react-close-icon-${(counter++).toString(36)}`, []);
  const ref = useRef();
  const rect = useRect(ref);
  usePlainCss(`#${idCloseIconPlaceholder}, #${idCloseIconNotPortaled} {
 position: absolute;
 top: 50%;
 right: 0;
 margin-top: calc(${width}px / 2 * -1);
 display: block;
 width: ${width}px;
 height: ${width}px; 
 pointer-events: none;
}

#${idCloseIconNotPortaled} {
  position: absolute !important;
}

#${idCloseIconNotPortaled}, #${idCloseIconPortaled} {
 cursor: pointer;
 pointer-events: all;
 ${framed ? "outline: rgb(255, 94, 94) solid 5px;" : ""}
}

#${idCloseIconPortaled} {
 position: fixed !important;
 ${rect ? `top: ${rect.y}px;left: ${rect.x}px;display:block;` : "display:none;"}
 width: ${width}px;
 height: ${width}px;
 z-index: 99;
}
#${idCloseIconPortaled}:after, #${idCloseIconNotPortaled}:after {
  width: auto !important;
  white-space: nowrap !important;
}
#${idCloseIconPortaled}>span:before, #${idCloseIconPortaled}>span:after,
#${idCloseIconNotPortaled}>span:before, #${idCloseIconNotPortaled}>span:after {
 position: absolute;
 top: 50%;
 left: 50%;
 width: ${thickness}px;
 height: ${width}px;
 background-color: ${color};
 transform: rotate(45deg) translate(-50%, -50%);
 transform-origin: top left;
 transition: all 420ms;
 content: '';
 opacity: 0.5;
 pointer-events: none;
}
#${idCloseIconPortaled}>span:after,
#${idCloseIconNotPortaled}>span:after {
 transform: rotate(-45deg) translate(-50%, -50%);
}
#${idCloseIconPortaled}>span:hover:before, #${idCloseIconPortaled}>span:hover:after,
#${idCloseIconNotPortaled}>span:hover:before, #${idCloseIconNotPortaled}>span:hover:after {
 opacity: 1;
 width: ${thickness + 1}px;
}`);
  return renderInContainer ? h(Fragment, null, h("div", {
    id: idCloseIconPlaceholder,
    ref: ref
  }), rect?.y > 0 && h(Portal, {
    renderInContainer: renderInContainer
  }, h(Tooltip, {
    title: tooltipText,
    id: idCloseIconPortaled,
    onClick: onClick,
    position: rect.y > 50 ? "top-left" : "left",
    always: tooltipAlways
  }, h("span", null)))) : h(Tooltip, {
    title: tooltipText,
    id: idCloseIconNotPortaled,
    onClick: onClick,
    position: "top-left",
    always: tooltipAlways
  }, h("span", {
    ref: ref
  }));
};
export { CloseIcon };