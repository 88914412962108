import { useEffect } from "react";
import { useBanner } from "../contexts";
import { usePlainCss } from ".";

/**
 * Pass an HTML refs and we will provide a root stylesheet with the height dimensions and variables
 * with the calculated height. This is needed as we need to do some positioning of header and footer.
 */
function useRenderedHeightsAsCssVars(element, name) {
  const {
    pageRequestUuid4
  } = useBanner();

  // We do directly modify the inline CSS as we do not want to trigger a rerender for the complete banner content
  const cssId = usePlainCss("", `useRenderedHeightsAsCssVars-${name}`);
  const cssElement = document.getElementById(cssId);
  useEffect(() => {
    let previousHeight = 0;
    const recalculate = () => {
      const currentHeight = element.getBoundingClientRect().height;
      if (previousHeight !== currentHeight) {
        previousHeight = currentHeight;
        cssElement.innerHTML = `#${pageRequestUuid4}{--rendered-height-${name}: ${Math.floor(currentHeight)}px;--rendered-height-raw-${name}: ${currentHeight}px}`;
      }
    };
    if (!element) {
      return () => {
        // Silence is golden.
      };
    }

    // Support animations
    const animateContainer = document.querySelector(`#${pageRequestUuid4} div[class*="animate__"]`);
    animateContainer.addEventListener("animationend", recalculate);
    if (window.ResizeObserver) {
      const ro = new ResizeObserver(recalculate);
      ro.observe(element);
      return () => {
        ro.disconnect();
        animateContainer.removeEventListener("animationend", recalculate);
      };
    } else {
      const interval = setInterval(recalculate, 150);
      return () => {
        clearInterval(interval);
        animateContainer.removeEventListener("animationend", recalculate);
      };
    }
  }, [element, name]);
}
export { useRenderedHeightsAsCssVars };