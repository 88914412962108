import { commonRequest } from "@devowl-wp/utils";
import { getOptionsFromWindow } from "../utils";
import { locationRestConsentClearDelete } from "../wp-api";

/**
 * Delete cookies on server side (currently only `http`).
 */
function deleteCookiesServer(cookies) {
  const {
    restNamespace,
    restRoot,
    restQuery,
    restNonce
  } = getOptionsFromWindow();
  commonRequest({
    location: locationRestConsentClearDelete,
    options: {
      restNamespace,
      restRoot,
      restQuery,
      restNonce
    },
    sendRestNonce: false,
    params: {
      cookies: cookies.join(",")
    }
  });
}
export { deleteCookiesServer };