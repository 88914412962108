import { useBannerOrBlocker, useLegalLinks } from "..";
function joinWithAndSeparator(arr, andSeparator) {
  if (arr.length > 1) {
    arr.splice(arr.length - 1, 0, "{{andSeparator}}");
  }
  return arr.join(", ").replace(/,\s+{{andSeparator}},\s+/g, andSeparator);
}

/**
 * @see https://gist.github.com/unruthless/413930
 * @see https://stackoverflow.com/a/23630325/5506547
 */
const supWithLineHeightFix = '<sup style="vertical-align:top;line-height:100%;position:initial;">';
function useCommonTeachings() {
  let {
    disableDataProcessingInUnsafeCountries,
    disableAgeNotice,
    disableListServicesNotice
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    blocker,
    groups,
    ePrivacyUSA: isEPrivacyUSA,
    ageNotice: isAgeNotice,
    listServicesNotice: isListServicesNotice,
    texts: {
      description,
      ePrivacyUSA,
      ageNoticeBanner,
      listServicesNotice,
      consentForwardingExternalHosts: consentForwardingExternalHostsText
    },
    tcf,
    consentForwardingExternalHosts,
    individualPrivacyOpen,
    individualTexts: {
      description: individualDescription
    },
    essentialGroup,
    designVersion,
    i18n: {
      andSeparator,
      tcf: tcfBannerI18n
    },
    keepVariablesInTexts
  } = useBannerOrBlocker();
  const {
    linkPrivacyPolicy
  } = useLegalLinks();

  // Description text (combination of configured text and Consent Forwarding)
  let consentForwardingExternalHostsFinalText = "";
  if (consentForwardingExternalHosts && !keepVariablesInTexts) {
    consentForwardingExternalHostsFinalText = consentForwardingExternalHostsText.replace(/{{websites}}/g, consentForwardingExternalHosts.join(", "));
  }
  let useDescription = [blocker ? [blocker.description, designVersion > 2 ? description : false].filter(Boolean).join("\n\n") : individualPrivacyOpen ? individualDescription : description, consentForwardingExternalHostsFinalText].filter(Boolean).join(" ");
  if (!keepVariablesInTexts) {
    // Place policy link into the text https://regex101.com/r/ayeFVy/1
    useDescription = useDescription.replace(/{{privacyPolicy}}(.*){{\/privacyPolicy}}/gi, linkPrivacyPolicy ? `<a href="${linkPrivacyPolicy.url}" style="text-decoration:underline;color:inherit;" onmouseenter="this.style.textDecoration='none'" onmouseleave="this.style.textDecoration='underline'" target="_blank">$1</a>` : "$1");
  }

  // Teaching: Data processing in unsafe countries (formerly USA ePrivacy)
  const servicesProcessingUnsafeCountries = groups.map(_ref => {
    let {
      items
    } = _ref;
    return items.filter(_ref2 => {
      let {
        ePrivacyUSA
      } = _ref2;
      return ePrivacyUSA;
    });
  }).flat();
  const tcfVendorsProcessingUnsafeCountries = process.env.IS_TCF && tcf ? Object.values(tcf.gvl.vendors).filter(vendor => vendor.additionalInformation?.internationalTransfers || tcf.original.vendorConfigurations[vendor.id].ePrivacyUSA === 1) : [];
  let useDataProcessingInUnsafeCountries = !disableDataProcessingInUnsafeCountries && (servicesProcessingUnsafeCountries.length > 0 || tcfVendorsProcessingUnsafeCountries.length > 0) && isEPrivacyUSA ? ePrivacyUSA : "";

  // Teaching: Age notice
  const useAgeNotice = isAgeNotice && !disableAgeNotice ? ageNoticeBanner : "";

  // Teaching: List services notice
  let useListServicesNotice = "";
  if (isListServicesNotice && !disableListServicesNotice) {
    const listServicesNoticeLower = listServicesNotice.toLowerCase();
    const hasBothPlaceholders = listServicesNoticeLower.indexOf("{{services}}") > -1 && listServicesNoticeLower.indexOf("{{servicegroups}}") > -1;
    const nonEssentialGroups = groups.filter(_ref3 => {
      let {
        slug
      } = _ref3;
      return slug !== essentialGroup;
    });
    const flattenSeparator = "~;~";
    const listServicesNames = joinWithAndSeparator(nonEssentialGroups.map((_ref4, groupIdx) => {
      let {
        items
      } = _ref4;
      return items.map(_ref5 => {
        let {
          name,
          ePrivacyUSA
        } = _ref5;
        return `${name}${supWithLineHeightFix}${[hasBothPlaceholders ? groupIdx + 1 : "", useDataProcessingInUnsafeCountries && ePrivacyUSA ? "U" : ""].filter(Boolean).join(",")}</sup>`;
      }).join(flattenSeparator);
    }).join(flattenSeparator).split(flattenSeparator), andSeparator);
    if (listServicesNames) {
      const listServiceGroupsNames = joinWithAndSeparator(nonEssentialGroups.map((_ref6, groupIdx) => {
        let {
          name
        } = _ref6;
        return `${name}${supWithLineHeightFix}${hasBothPlaceholders ? groupIdx + 1 : ""}</sup>`;
      }), andSeparator);
      useListServicesNotice = `<span>${listServicesNotice}</span>${tcf && !individualPrivacyOpen ? ` <span>${tcfBannerI18n.listOfServicesAppendix}</span>` : ""}`;
      if (!keepVariablesInTexts) {
        // Place service list into the text
        useListServicesNotice = useListServicesNotice.replace(/{{services}}/gi, listServicesNames)
        // Place service group list into the text
        .replace(/{{serviceGroups}}/gi, listServiceGroupsNames);
      }
      if (useDataProcessingInUnsafeCountries) {
        useDataProcessingInUnsafeCountries += `${supWithLineHeightFix}U</sup>`;
      }
    }
  }
  return {
    description: useDescription,
    teachings: [useDataProcessingInUnsafeCountries, useAgeNotice, useListServicesNotice].filter(Boolean)
  };
}
export { useCommonTeachings };