import { regExpPatternFromWildcardName } from "..";

/**
 * Synchronously check if a consent is given. See `window.consentApi.consent` for more documentation.
 */
function consentSync(manager, typeOrIdOrUniqueName, name,
/**
 * This can also be `*` to allow all hosts.
 */
host) {
  // Find matching cookie
  const found = [];
  const {
    groups,
    revisionHash
  } = manager.getOptions();
  const allCookies = groups.map(_ref => {
    let {
      items
    } = _ref;
    return items;
  }).flat();
  for (const row of allCookies) {
    if (typeof typeOrIdOrUniqueName === "number") {
      if (row.id === typeOrIdOrUniqueName) {
        found.push({
          cookie: row,
          relevance: 10
        });
      }
    } else if (typeof typeOrIdOrUniqueName === "string" && typeof name === "undefined" && typeof host === "undefined") {
      if (row.uniqueName === typeOrIdOrUniqueName) {
        found.push({
          cookie: row,
          relevance: 10
        });
      }
    } else {
      const {
        technicalDefinitions
      } = row;
      if (technicalDefinitions?.length) {
        // Check if technical information matches
        for (const td of technicalDefinitions) {
          const regexp = regExpPatternFromWildcardName(td.name);
          if (td.type === typeOrIdOrUniqueName && (td.name === name || name.match(regexp)) && (td.host === host || host === "*")) {
            found.push({
              cookie: row,
              // Create a priority by "relevance" inside the technical definitions
              // This is the case if e.g. another Cookie consumes the same technical cookie
              // Example: Vimeo uses Facebook Pixel, too
              relevance: technicalDefinitions.length + technicalDefinitions.indexOf(td) + 1
            });
            break;
          }
        }
      }
    }
  }
  const already = manager.getUserDecision();
  if (found.length) {
    const relevantCookie = found.sort((_ref2, _ref3) => {
      let {
        relevance: a
      } = _ref2;
      let {
        relevance: b
      } = _ref3;
      return a - b;
    })[0].cookie;

    // Check if consent is already given
    if (already && revisionHash === already.revision) {
      const consentCookieIds = Object.values(already.consent).flat();
      if (consentCookieIds.indexOf(relevantCookie.id) > -1) {
        return {
          cookie: relevantCookie,
          consentGiven: true,
          cookieOptIn: true
        };
      } else {
        return {
          cookie: relevantCookie,
          consentGiven: true,
          cookieOptIn: false
        };
      }
    } else {
      return {
        cookie: relevantCookie,
        consentGiven: false,
        cookieOptIn: false
      };
    }
  } else {
    return {
      cookie: null,
      consentGiven: !!already,
      cookieOptIn: true
    };
  }
}
export { consentSync };